<template>
  <div class="carouselAdmin">
    <h2>New and Noteworthy Carousel - Admin</h2>
    <ImageCropper class="imagePreviewer"
                  :imageData="item"
                  @updatedImage="updatedImage" />
    <div class="relatedContent">
      <h2>Related Content</h2>
      <p>Please choose the related article or resource this tile relates to.</p>
      <BrandStories class="brandStoryContainer"
                    v-model="item.brandStoryId" />
      <ResourceDocuments class="resourceDocumentContainer"
                         v-model="item.resourceDocumentId" />
      <ResourceVideos class="resourceVideoContainer"
                      v-model="item.resourceVideoId" />
      <WhpcStories class="whpcStoryContainer"
                   v-model="item.whpcStoryId" />
    </div>
    <form @submit.prevent="submit">
      <TextBox :label="'Tile Title (Long)'"
               :name="'title'"
               :required="true"
               :maxLength="50"
               v-model="item.title"
               class="textBox" />
      <TextBox :label="'Tile Title (Short)'"
               :name="'titleShort'"
               :required="true"
               :maxLength="24"
               v-model="item.titleShort"
               class="textBox" />
      <TextBox :label="'Tile Description'"
               :name="'description'"
               :required="true"
               :maxLength="140"
               v-model="item.description"
               class="textBox" />
      <TextBox :label="'Button Text (Long)'"
               :name="'buttonText'"
               :required="true"
               :maxLength="20"
               :placeholder="'Read More'"
               v-model="item.buttonText"
               class="textBox" />
      <TextBox :label="'Button Text (Short)'"
               :name="'buttonTextShort'"
               :required="true"
               :maxLength="10"
               v-model="item.buttonTextShort"
               :placeholder="'More'"
               class="textBox" />
      <TextBox :label="'Button URL'"
               :name="'buttonUrl'"
               :required="!this.btnUrlDisabled"
               :disabled="this.btnUrlDisabled"
               :placeholder="'https://effortlessguide.dealerconnection.com/'"
               v-model="item.buttonUrl"
               class="textBox" />
      <SelectElem :label="'Button Action'"
                  :name="'buttonAction'"
                  :id="'buttonActionSelect'"
                  v-model="item.buttonAction"
                  :required="!this.btnUrlDisabled"
                  :disabled="this.btnUrlDisabled"
                  :selectOptions="btnActionOptions" />
      <button type="submit">Save</button>
      <button type="button" class="cancel" @click="closeModal">Cancel</button>
    </form>
  </div>
</template>

<script>
import TextBox from '../formElements/TextBox.vue';
import SelectElem from '../formElements/Select.vue';
import BrandStories from './carouselAdminBrandStories.vue';
import ResourceDocuments from './carouselAdminResourceDocuments.vue';
import ResourceVideos from './carouselAdminResourceVideos.vue';
import WhpcStories from './carouselAdminWhpc.vue';
import ImageCropper from '../imageManipulation/ImageCropperForCarousel.vue';

export default {
  name: 'carouselAdmin',
  props: ['name', 'slide'],
  components: {
    TextBox,
    SelectElem,
    BrandStories,
    ResourceDocuments,
    ResourceVideos,
    WhpcStories,
    ImageCropper,
  },
  created() {
    return this.$store.getters['carouselStore/carouselTiles'];
  },
  data() {
    return {
      btnActionOptions: [
        {
          id: 1,
          selected: (this.slide.buttonAction === '_self' || this.slide.buttonAction === '_blank') ? (this.slide.buttonAction === '_self') : true,
          value: '_self',
          copy: 'Same Window',
        },
        {
          id: 2,
          selected: (this.slide.buttonAction === '_blank'),
          value: '_blank',
          copy: 'New Window',
        },
      ],
      item: this.slide,
      btnAction: null,
    };
  },
  computed: {
    btnUrlDisabled() {
      return !!this.item.resourceVideoId || !!this.item.resourceDocumentId;
    },
  },
  watch: {
    'item.brandStoryId': function setBrandStoryId(newValue) {
      if (!newValue) {
        return;
      }

      this.setUrl(`#/brandStory?id=${newValue}`);
      this.resetIds('bs');
    },
    'item.resourceDocumentId': function setResourceDocumentId(newValue) {
      if (!newValue) {
        return;
      }

      this.setUrl('');
      this.resetIds('rd');
    },
    'item.resourceVideoId': function setResourceideoId(newValue) {
      if (!newValue) {
        return;
      }

      this.setUrl('');
      this.resetIds('rv');
    },
    'item.whpcStoryId': function setWhpcStoryId(newValue) {
      if (!newValue) {
        return;
      }

      this.setUrl(`#/stories?id=${newValue}`);
      this.resetIds('ws');
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide(this.name);
    },
    resetIds(box) {
      if (box !== 'bs') {
        this.item.brandStoryId = null;
      }
      if (box !== 'rd') {
        this.item.resourceDocumentId = null;
      }
      if (box !== 'rv') {
        this.item.resourceVideoId = null;
      }
      if (box !== 'ws') {
        this.item.whpcStoryId = null;
      }
    },
    setUrl(url) {
      this.item.buttonUrl = url || '';
    },
    async submit() {
      if (this.btnUrlDisabled) {
        delete this.item.buttonUrl;
        delete this.item.buttonAction;
      }

      await this.$store.dispatch('carouselStore/updateCarouselTile', this.item);
      this.$store.dispatch('carouselStore/getCarouselTiles');
      this.closeModal();
    },
    async updatedImage(file) {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', file);
      this.item.assetId = thumbnailImageUploaded.id;
    },
  },
};
</script>

<style lang="scss" scoped>
  .carouselAdmin{
    width:80%;
    margin:0 auto;
    padding:10% 0;
    h2{
      margin:0 0 15px 0;
    }
    .relatedContent{
      width:90%;
      margin:0 auto;
      padding:15px;
      border:1px solid $LincolnGrayDark;
    }
    /deep/ label{
      width: 100%;
      padding: 15px 0;
      margin: 15px 0;
      /deep/ span{
        display: inline-block !important;
        color: RGBA(0, 0, 0, 0.75);
        font-size: 1rem;
        width: auto;
        padding: 0 15px;
        /deep/ span{
          color:inherit;
          width: auto;
          padding: 0 3px;
        }
      }
      /deep/ div{
        color: RGBA(0, 0, 0, 0.45);
        font-size: 0.75rem;
        width: 100%;
        padding: 0 15px;
      }
      /deep/ input{
        padding: 10px 15px 0 15px !important;
        line-height: normal;
        &:focus{
          outline: none;
        }
      }
    }
  }
</style>
