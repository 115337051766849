var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "brandStoriesList" },
    [
      _c("LoadingOverlay", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoader,
            expression: "showLoader",
          },
        ],
      }),
      _c(
        "masonry",
        {
          key: _vm.key,
          attrs: {
            cols: { default: 7, 600: 2, 768: 3, 992: 4, 1200: 5 },
            gutter: { default: "15px" },
          },
        },
        _vm._l(this.brandStories, function (brandStory, index) {
          return _c("BrandStoryTile", {
            key: "brandStoryTile" + brandStory.id,
            attrs: { brandStory: brandStory, index: index },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }