var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carouselContainer", class: { clicked: _vm.tileSelected } },
    [
      _c("div", { staticClass: "arrow left", on: { click: _vm.clickedLeft } }, [
        _c("div", { staticClass: "chevron left" }),
      ]),
      _c("TileSide", {
        ref: "leftTransition",
        attrs: {
          item: _vm.items[2],
          isActive: _vm.isActiveLeft,
          isActiveLeft: _vm.isActiveLeft,
          isClicked: _vm.isClicked,
          side: _vm.left,
        },
        on: { selectedTile: _vm.selectedTile },
      }),
      _c("TileSide", {
        attrs: {
          item: _vm.items[0],
          isActive: _vm.isActiveLeft || _vm.isActiveRight,
          isClicked: _vm.isClicked,
          leftAnimation: _vm.leftAnimation,
          rightAnimation: _vm.rightAnimation,
          side: _vm.front,
        },
        on: {
          selectedTile: _vm.selectedTile,
          showCarouselAdmin: function ($event) {
            return _vm.showCarouselAdmin(_vm.items[0])
          },
        },
      }),
      _c("TileSide", {
        ref: "rightTransition",
        attrs: {
          item: _vm.items[1],
          isActive: _vm.isActiveRight,
          isActiveRight: _vm.isActiveRight,
          isClicked: _vm.isClicked,
          side: _vm.right,
        },
        on: { selectedTile: _vm.selectedTile },
      }),
      _c(
        "div",
        { staticClass: "arrow right", on: { click: _vm.clickedRight } },
        [_c("div", { staticClass: "chevron right" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }