<template>
  <div class="brandStoriesList">
    <LoadingOverlay v-show="showLoader" />
    <masonry
      :cols="{default: 7, 600: 2, 768: 3, 992: 4, 1200: 5}"
      :gutter="{default: '15px'}"
      :key="key"
    >
      <BrandStoryTile
        v-for="(brandStory, index) in this.brandStories"
        :key="'brandStoryTile' + brandStory.id"
        :brandStory="brandStory"
        :index="index"
      />
    </masonry>
  </div>
</template>

<script>
import BrandStoryTile from './BrandStoryTile.vue';
import LoadingOverlay from '../../../loaders/LoadingOverlay.vue';

export default {
  name: 'BrandStoriesList',
  components: {
    BrandStoryTile,
    LoadingOverlay,
  },
  async created() {
    if (this.brandStories.length === 0) {
      this.showLoader = true;
    }
    await this.$store.dispatch('brandStoryStore/getApprovedBrandStories');
    this.showLoader = false;
  },
  data() {
    return {
      key: true,
      showLoader: false,
    };
  },
  methods: {
    rerenderMasonry() {
      this.key = !this.key;
    },
  },
  computed: {
    cols() {
      const defaultCols = this.brandStories.length === 2 ? 2 : 3;

      return {
        default: defaultCols,
        990: 2,
        650: 1,
      };
    },
    brandStories() {
      const stories = this.$store.getters['brandStoryStore/approvedBrandStories'];

      const currentTenet = this.$store.getters['tenetStore/currentTenet'];
      const currentArea = this.$store.getters['businessAreaStore/currentArea'];

      if (!currentTenet || !currentArea) {
        return stories;
      }

      const areaMatches = area => area.id === currentArea.id;
      const tenetMatches = tenet => tenet.id === currentTenet.id;

      const matchesBoth = story => story.businessAreas.some(areaMatches)
        && story.effortlessTenets.some(tenetMatches);

      this.rerenderMasonry();

      return stories.filter(matchesBoth);
    },
  },
};
</script>
