<template>
  <div>
    <div>Resource Documents</div>
    <SelectElem
                :name="'resourceDocument'"
                :id="'resourceDocumentSelect'"
                v-model="resourceDocumentIdValue"
                :selectOptions="resourceDocuments"
                @input="handleChange" />
  </div>
</template>

<script>
import SelectElem from '../formElements/Select.vue';

export default {
  name: 'carouselAdminResourceDocuments',
  props: [
    'name',
    'slide',
    'value',
  ],
  components: {
    SelectElem,
  },
  async created() {
    await this.$store.dispatch('resourceStore/getDocuments');
  },
  computed: {
    resourceDocuments() {
      const documents = this.$store.getters['resourceStore/documents'].map(a => ({
        value: a.id,
        copy: a.title,
      }));
      documents.unshift({
        value: null,
        copy: 'None',
      });
      return documents;
    },
  },
  data() {
    return {
      resourceDocumentIdValue: this.value || null,
    };
  },
  methods: {
    handleChange(val) {
      this.$emit('input', val);
    },
  },
  watch: {
    value(newValue) {
      this.resourceDocumentIdValue = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
