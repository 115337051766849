<template>
  <label>
    <span v-if="label">
      {{this.label}}<span v-if="required">*</span>
    </span>
    <select :name="name"
            :required="required"
            v-model="selected"
            :disabled="disabled"
            @change="handleChange">
      <option v-for="(selectOption, index) in selectOptions"
              :key="'selectOption'+ index"
              :value="selectOption.value">
        {{selectOption.copy}}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: 'Select',
  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    required: Boolean,
    value: String,
    copy: String,
    selectOptions: Array,
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value || null,
    };
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value);
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
  label{
    display:inline-block;
    background:white;
    color:$LincolnGrayDark;
    border:1px solid $LincolnGrayDark;
    width:100%;
    padding:15px 0;
    margin:15px 0;
    span {
      display: inline-block;
      color: RGBA(0,0,0,0.75);
      font-size:1rem;
      width:auto;
      span {
        color:$LincolnWarn;
        width:auto;
        padding:0 3px;
      }
    }
    div{
      color: RGBA(0, 0, 0, 0.45);
      font-size: 0.75rem;
      width: 100%;
      padding: 0 15px;
    }
    select {
      border:none;
      display:inline-block;
      width:100%;
      padding:10px 15px 0 15px !important;
      line-height:normal;
      &:focus{
        outline:none;
      }
    }
  }
</style>
