var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("Resource Documents")]),
      _c("SelectElem", {
        attrs: {
          name: "resourceDocument",
          id: "resourceDocumentSelect",
          selectOptions: _vm.resourceDocuments,
        },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.resourceDocumentIdValue,
          callback: function ($$v) {
            _vm.resourceDocumentIdValue = $$v
          },
          expression: "resourceDocumentIdValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }