<template>
  <div @click="filterOptionSelected" v-scroll-to="'#tenetAnchor'">
    <div class="dropDownBtnElement" :class="showClass">
      <div class="title">{{name}}</div>
    </div>
  </div>
</template>

<script>
import '../../../plugins/vueScrollTo';

export default {
  name: 'DropDownBtnElement',
  props: ['id', 'name'],
  data() {
    return {
      showClass: '',
    };
  },
  methods: {
    filterOptionSelected() {
      this.$emit('selectedItem', { id: this.id, name: this.name });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropDownBtnElement {
  background-color: $LincolnGray;
  color: black;
  text-align: center;
  border-top: 1px solid white;
  border-right: 1px solid white;
  padding: 1em 0;
  background-color: $LincolnGrayLighter;
  text-transform: uppercase;
  @include font-size(0.75rem);
  font-weight: $bold;

  &:hover {
    background-color: $LincolnGrayDark;
    color: white;
    cursor: pointer;
  }
}
</style>
