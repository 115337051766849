<template>
  <div class="submission-wrapper">
    <div>
      <div class="title">Submit Your Ideas</div>
      <div class="subtitle">Tell Us Your Brand Story</div>
    </div>
    <div @click="showSubmission" class="upload-icon">
      <font-awesome-icon :icon="['fal', 'arrow-from-top']" size="2x" flip="vertical" />
    </div>
  </div>
</template>

<script>
import BrandStorySubmission from './BrandStorySubmission.vue';

export default {
  name: 'BrandStorySubmissionContainer',
  methods: {
    modalDef(width) {
      const name = 'BrandStorySubmission';

      this.$modal.show(
        BrandStorySubmission,
        { name },
        {
          name,
          adaptive: true,
          height: 'auto',
          width,
          scrollable: true,
        },
      );
    },

    showSubmissionMobile() {
      this.modalDef('97%');
    },

    showSubmissionDesktop() {
      this.modalDef('70%');
    },

    mobile() {
      return this.showSubmissionMobile;
    },
    desktop() {
      return this.showSubmissionDesktop;
    },
  },
  computed: {
    showSubmission() {
      return this.$mq === 'lg' ? this.desktop() : this.mobile();
    },
  },
};
</script>

<style lang="scss" scoped>
.submission-wrapper {
  color: white;
  display: flex;
  align-items: center;
  z-index: 2;

  .title {
    text-transform: uppercase;
    margin: 0;
    @include font-size(2rem);
  }

  .subtitle {
    margin: 0;
    @include font-size(1.625rem);
  }

  .upload-icon {
    margin-left: 20px;
    border: 2px solid #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      color: $LincolnOrange;
      border-color: $LincolnOrange;
    }
  }
}
</style>
