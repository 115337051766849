var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "submission-wrapper" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "upload-icon", on: { click: _vm.showSubmission } },
      [
        _c("font-awesome-icon", {
          attrs: {
            icon: ["fal", "arrow-from-top"],
            size: "2x",
            flip: "vertical",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "title" }, [_vm._v("Submit Your Ideas")]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("Tell Us Your Brand Story"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }