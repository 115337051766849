<template>
  <div>
    <div>WHPC Story</div>
    <SelectElem
                :name="'whpcStory'"
                :id="'whpcStorySelect'"
                v-model="whpcStoryIdValue"
                :selectOptions="whpcStories"
                @input="handleChange" />
  </div>
</template>

<script>
import SelectElem from '../formElements/Select.vue';

export default {
  name: 'carouselAdminWhpc',
  props: [
    'name',
    'slide',
    'value',
  ],
  components: {
    SelectElem,
  },
  async created() {
    await this.$store.dispatch('whpcStore/getStories');
  },
  computed: {
    whpcStories() {
      const stories = this.$store.getters['whpcStore/stories'].map(a => ({
        value: a.id,
        copy: a.subject,
      }));
      stories.unshift({
        value: null,
        copy: 'None',
      });
      return stories;
    },
  },
  data() {
    return {
      whpcStoryIdValue: this.value || null,
    };
  },
  methods: {
    handleChange(val) {
      this.$emit('input', val);
    },
  },
  watch: {
    value(newValue) {
      this.whpcStoryIdValue = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
