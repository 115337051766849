var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tileSide",
      class:
        ((_obj = {
          active: _vm.isActive,
          clicked: _vm.isClicked,
          hover: _vm.isHovering,
          leftAnimation: _vm.leftAnimation,
          rightAnimation: _vm.rightAnimation,
        }),
        (_obj[_vm.side] = true),
        _obj),
    },
    [
      _c(
        "div",
        {
          staticClass: "containerTile",
          class: { hover: _vm.isHovering, active: _vm.isActive },
          on: {
            mouseover: _vm.tileOver,
            mouseout: _vm.tileOut,
            click: function ($event) {
              $event.preventDefault()
              return _vm.clickedAction($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "sectionBox left",
              class: { active: _vm.isActiveLeft },
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.item.titleShort)),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "sectionBox main", class: { active: _vm.isActive } },
            [
              _c("div", { staticClass: "copyBox", class: _vm.side }, [
                _c("div", { staticClass: "title titleFront" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ]),
                _c("div", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.item.description)),
                ]),
                _vm.item.buttonAction ||
                _vm.item.resourceVideoId ||
                _vm.item.resourceDocumentId
                  ? _c("a", { staticClass: "button" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.displayBtnTxt) +
                          " >\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "sectionBox right",
              class: { active: _vm.isActiveRight },
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.item.titleShort)),
              ]),
            ]
          ),
          _vm.item.asset
            ? _c("div", {
                staticClass: "sectionImage",
                class: { active: _vm.isActive, videoPreview: _vm.isVideo },
                style: this.bkgImg,
              })
            : _vm._e(),
          _vm.item.resourceVideo
            ? _c(
                "div",
                {
                  staticClass: "sectionVideo",
                  class: { active: _vm.isActive },
                },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.side === "front"
                      ? _c("div", { staticClass: "videoWrapper" }, [
                          _c("iframe", {
                            attrs: {
                              src: _vm.getVideo(),
                              frameborder: "0",
                              webkitallowfullscreen: "",
                              mozallowfullscreen: "",
                              allowfullscreen: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isAdmin && _vm.side === "front"
            ? _c(
                "div",
                {
                  staticClass: "sectionAdmin",
                  class:
                    ((_obj$1 = {}),
                    (_obj$1[_vm.side] = true),
                    (_obj$1.active = _vm.isActive),
                    _obj$1),
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "pencil"] },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.showCarouselAdmin($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }