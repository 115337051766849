var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "imageCropperForStory" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("Carousel Previewer and Uploader"),
      ]),
      _c("input", {
        staticClass: "uploadFile",
        attrs: { type: "file", name: "image", accept: "image/*" },
        on: { change: _vm.setImage },
      }),
      _c(
        "div",
        { staticClass: "cropperContainer" },
        [
          _c("vue-cropper", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imgSrc != "",
                expression: "imgSrc != ''",
              },
            ],
            ref: "cropper",
            attrs: {
              guides: true,
              "view-mode": 2,
              "drag-mode": "crop",
              aspectRatio: _vm.aspectRatio,
              "auto-crop-area": 0.63,
              "min-container-width": 420,
              "min-container-height": 265,
              background: true,
              rotatable: false,
              src: _vm.imgSrc,
              alt: "",
              cropend: _vm.cropImage,
              ready: _vm.cropImage,
              "img-style": { width: "420px", height: "265px" },
            },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c("ButtonSubmit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.cropImg,
            expression: "cropImg",
          },
        ],
        attrs: { copy: "Save Image" },
        on: { callback: _vm.selectedImage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgPreviewContainer" }, [
      _c("div", { staticClass: "titlePreview" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }