<template>
  <div class="home">
    <div class="boxedCopy">
      {{this.homeCopy.boxCopy1}}
      <b>{{this.homeCopy.boxCopy2}}</b>
    </div>
    <div
      class="imgHeader"
      :style="{
    backgroundImage: 'linear-gradient(to bottom right, rgba(0,0,0,0.8), rgba(0,0,0,0.2)),' +
    'url('+require('../assets/images/homeHeader.png')+')'}"
    >
      <BrandStorySubmissionContainer />
      <div class="scroll-tenents">
        <div>
          <font-awesome-icon :icon="['fas', 'caret-left']" class="icon left" />
        </div>
        <div class="ellipsis left">...</div>
        <div>Scroll Tenents</div>
        <div class="ellipsis right">...</div>
        <div>
          <font-awesome-icon :icon="['fas', 'caret-right']" class="icon right" />
        </div>
      </div>
    </div>
    <div class="tenetSelector">
      <LoadingOverlay v-show="fetchingData" />
      <div class="containerDropDownBtn">
        <DropDownBtn
          v-for="(tenet, index) in this.tenets"
          :key="'tenet' + tenet.id"
          :id="tenet.id"
          :name="tenet.name"
          :dropDownItems="businessAreas"
          :class="{last : index === (tenets.length-1)}"
        />
      </div>
    </div>
    <div class="content-wrapper">
      <div class="carousel-wrapper">
        <div class="newNoteTitle">{{this.homeCopy.subHeaderTitle}}</div>
        <Carousel class="carousel" />
      </div>
      <a id="tenetAnchor"></a>
      <div class="tenetFilter">{{selectedTenet}}</div>
      <BrandStoriesList class="brandStories" />
    </div>
  </div>
</template>

<script>
import Helper from '../mixins/helperMethods.vue';
import DropDownBtn from '../components/stories/brandStories/DropDownBtn.vue';
import BrandStorySubmissionContainer from '../components/stories/brandStories/BrandStorySubmissionContainer.vue';
import Carousel from '../components/carousel/carouselContainer.vue';
import BrandStoriesList from '../components/stories/brandStories/list/BrandStoriesList.vue';
import LoadingOverlay from '../components/loaders/LoadingOverlay.vue';

import dataHomeCopy from '../data/homeCopy';

export default {
  mixins: [Helper],
  name: 'Home',
  components: {
    DropDownBtn,
    BrandStorySubmissionContainer,
    Carousel,
    BrandStoriesList,
    LoadingOverlay,
  },
  data() {
    return {
      homeCopy: dataHomeCopy,
      fetchingData: false,
    };
  },
  computed: {
    tenets() {
      return this.$store.getters['tenetStore/effortlessTenets'];
    },
    businessAreas() {
      return this.$store.getters['businessAreaStore/businessAreas'];
    },
    selectedTenet() {
      let title = 'Most Popular';
      const tenet = this.$store.getters['tenetStore/currentTenet'];
      if (tenet != null) {
        title = tenet.name;
      }
      return title.toUpperCase();
    },
  },
  async created() {
    this.$analytics.trackPageView();
    if (this.businessAreas.length === 0) {
      this.fetchingData = true;
    }
    const tenets = this.$store.dispatch('tenetStore/getEffortlessTenets');
    const areas = this.$store.dispatch('businessAreaStore/getBusinessAreas');

    await Promise.all([tenets, areas]);
    this.fetchingData = false;
  },
};
</script>

<style lang="scss" scoped>
.home {
  .boxedCopy {
    display: none;
    font-size: 10px;
    padding: 5px 10px;
    border: 2px solid white;
    color: white;
    position: absolute;
    top: 80px;
    left: 15px;
    z-index: 1;

    @include respond(md) {
      display: inline-block;
    }
  }

  .imgHeader {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 46% 0;

    @include respond(sm) {
      padding: 21% 0;
    }

    @include respond(md) {
      padding: 18% 0;
    }

    @include respond(lg) {
      padding: 15% 0;
    }

    .scroll-tenents {
      color: white;
      position: absolute;
      bottom: 15px;
      font-weight: bold;
      display: flex;
      align-items: center;
      line-height: 0;
      font-size: 1.2rem;

      @include respond(lg) {
        display: none;
      }

      .icon {
        &.left {
          margin-right: 3px;
        }

        &.right {
          margin-left: 1px;
        }
      }

      .ellipsis {
        height: 8px;
        letter-spacing: 2px;

        &.left {
          margin-right: 5px;
        }

        &.right {
          margin-left: 5px;
        }
      }
    }
  }

  .tenetSelector {
    position: sticky;
    top: 0;
    z-index: 999;
    height: 3.5rem;

    .containerDropDownBtn {
      display: flex;
      overflow-x: auto;
      z-index: 31;

      &::-webkit-scrollbar {
        display: none;
      }

      > * {
        flex: 1;
      }

      .last {
        /deep/ .dropDownBtn {
          border-right: none;
        }
      }
    }
  }

  .content-wrapper {
    @include respond(sm) {
      padding-top: 50px;
    }

    .carousel-wrapper {
      display: none;

      @include respond(sm) {
        display: block;
      }

      .newNoteTitle {
        text-align: center;
        @include font-size(1.875rem);
        color: rgb(50, 64, 74);
      }

      .carousel {
        margin: 50px auto;
        max-width: 1500px;
      }
    }

    #tenetAnchor {
      display: block;
      position: relative;
      top: -50px;
      visibility: hidden;
    }

    .tenetFilter {
      padding-top: 20px;
      text-align: center;
      @include font-size(1.875rem);
      color: rgb(50, 64, 74);
    }

    .brandStories {
      margin: 50px 0;
    }
  }
}
</style>
