<template>
  <div class="carouselContainer" :class="{clicked:tileSelected}">
    <div class="arrow left" @click="clickedLeft">
      <div class="chevron left"></div>
    </div>
    <!-- <TileSide class="behind"
              :item="items[3]"
              :side="left" /> -->
    <TileSide ref="leftTransition"
              :item="items[2]"
              :isActive="isActiveLeft"
              :isActiveLeft="isActiveLeft"
              :isClicked="isClicked"
              :side="left"
              @selectedTile="selectedTile" />
    <TileSide :item="items[0]"
              :isActive="isActiveLeft||isActiveRight"
              :isClicked="isClicked"
              :leftAnimation="leftAnimation"
              :rightAnimation="rightAnimation"
              :side="front"
              @selectedTile="selectedTile"
              @showCarouselAdmin="showCarouselAdmin(items[0])" />
    <TileSide ref="rightTransition"
              :item="items[1]"
              :isActive="isActiveRight"
              :isActiveRight="isActiveRight"
              :isClicked="isClicked"
              :side="right"
              @selectedTile="selectedTile" />
    <!-- <TileSide class="behind"
              :item="items[2]"
              :side="right" /> -->
    <div class="arrow right"
         @click="clickedRight">
      <div class="chevron right"></div>
    </div>
  </div>
</template>

<script>
import TileSide from './carouselTile.vue';
import CarouselAdmin from './carouselAdmin.vue';
import ResourceHandlers from '../../mixins/resourceHandlers.vue';

export default {
  name: 'CarouselContainer',
  props: ['carouselItems'],
  components: {
    TileSide,
  },
  mixins: [ResourceHandlers],
  created() {
    this.$store.dispatch('carouselStore/getCarouselTiles');
  },
  computed: {
    items() {
      return this.$store.getters['carouselStore/carouselTiles'];
    },
  },
  data() {
    return {
      front: 'front',
      left: 'left',
      right: 'right',
      isClicked: false,
      tileSelected: false,
      leftAnimation: false,
      rightAnimation: false,
      isActiveLeft: false,
      isActiveRight: false,
    };
  },
  methods: {
    clickedLeft() {
      this.selectedTile('left');
    },
    clickedRight() {
      this.selectedTile('right');
    },
    selectedTile(action) {
      if (this.tileSelected === true) return;
      this.tileSelected = true;
      if (action === 'front') {
        this.$analytics.trackEventBtnPage('CarouselCenter');
        if (this.items[0].resourceDocumentId) {
          this.downloadDocument(this.items[0].resourceDocumentId);
        } else if (this.items[0].resourceVideoId) {
          this.showVideoModal(this.items[0].resourceVideoId);
        } else {
          const element = {
            href: this.items[0].buttonUrl || null,
            target: this.items[0].buttonAction,
          };
          if (element.href) {
            if (element.target === '_self') {
              window.location = element.href;
            } else {
              window.open(element.href, element.target);
            }
          }
        }
        this.tileSelected = false;
      } else if (action === 'left') {
        this.$analytics.trackEventBtnPage('CarouselLeft');
        this.leftAnimation = true;
        this.isActiveLeft = true;
        setTimeout(() => {
          this.$store.dispatch('carouselStore/updateCarouselPosition', 'left');
          this.leftAnimation = false;
          this.isActiveLeft = false;
          this.tileSelected = false;
        }, 900);
      } else if (action === 'right') {
        this.$analytics.trackEventBtnPage('CarouselRight');
        this.rightAnimation = true;
        this.isActiveRight = true;
        setTimeout(() => {
          this.$store.dispatch('carouselStore/updateCarouselPosition', 'right');
          this.rightAnimation = false;
          this.isActiveRight = false;
          this.tileSelected = false;
        }, 900);
      } else {
        this.tileSelected = false;
      }
    },
    showCarouselAdmin(slide) {
      const name = 'CarouselAdmin';
      this.$modal.show(CarouselAdmin, { name, slide }, {
        name,
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carouselContainer {
  position: relative;
  height: 264px;

  .arrow {
    position: absolute;
    top: 0;
    width: 5%;
    height: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 0;
    }
  }
  .tileSide {
    position: absolute;
    top: 0;
    width: 20%;
    height: 264px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    &.left {
      left: 52px;
      z-index: 10;

      @include respond(md) {
        left: 62px;
      }

      @include respond(lg) {
        left: 85px;
      }

      &.behind {
        z-index: 1;
      }
      &.active {
        animation-name: tileLeftSideToFront;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
    }
    &.front {
      position: absolute;
      top: 0;
      left: 24%;
      width: 50%;
      height: 264px;
      z-index: 30;
      &.leftAnimation {
        animation-name: tileFrontToRightSide;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
      &.rightAnimation {
        animation-name: tileFrontToLeftSide;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
    }
    &.right {
      right: 50px;
      z-index: 10;

      @include respond(md) {
        right: 60px;
      }

      @include respond(lg) {
        right: 75px;
      }

      &.behind {
        z-index: 1;
      }
      &.active {
        animation-name: tileRightSideToFront;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
.chevron {
  position: relative;
  top: 50%;
  width: 2vmin;
  height: 2vmin;
  background: transparent;
  border-top: 0.5vmin solid $LincolnGrayLighter;
  border-right: 0.5vmin solid $LincolnGrayLighter;
  box-shadow: 0 0 0 $LincolnGrayLighter;
  transition: all 200ms ease;
  cursor: pointer;
  &.left {
    left: 50%;
    transform: translate3d(0, -50%, 0) rotate(-135deg);
  }
  &.right {
    right: -40%;
    transform: translate3d(0, -50%, 0) rotate(45deg);
  }
  &:hover {
    border-color: $LincolnOrange;
    box-shadow: 0.5vmin -0.5vmin 0 white;
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -60%) rotate(45deg);
    width: 200%;
    height: 200%;
  }
}
@keyframes tileLeftSideToFront {
  0% {
    left: 5%;
    width: 20%;
  }
  25% {
    left: 5%;
    width: 20%;
  }
  75% {
    left: 25%;
    width: 50%;
  }
  100% {
    left: 25%;
    width: 50%;
  }
}
@keyframes tileFrontToLeftSide {
  0% {
    left: 25%;
    width: 50%;
  }
  25% {
    left: 25%;
    width: 50%;
  }
  75% {
    left: 5%;
    width: 20%;
  }
  100% {
    left: 5%;
    width: 20%;
  }
}
@keyframes tileFrontToRightSide {
  0% {
    left: 25%;
    width: 50%;
  }
  25% {
    left: 25%;
    width: 50%;
  }
  75% {
    left: 75%;
    width: 20%;
  }
  100% {
    left: 75%;
    width: 20%;
  }
}
@keyframes tileRightSideToFront {
  0% {
    left: 70%;
    width: 20%;
  }
  25% {
    left: 75%;
    width: 20%;
  }
  75% {
    left: 25%;
    width: 50%;
  }
  100% {
    left: 25%;
    width: 50%;
  }
}
</style>
