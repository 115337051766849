<template>
  <div class="tileSide"
       :class="{
          active:isActive,
          clicked:isClicked,
          hover:isHovering,
          leftAnimation:leftAnimation,
          rightAnimation:rightAnimation,
          [side]: true
        }">
    <div class="containerTile"
         :class="{hover:isHovering,active:isActive}"
         @mouseover="tileOver"
         @mouseout="tileOut"
         @click.prevent="clickedAction">
      <div class="sectionBox left"
           :class="{active:isActiveLeft}">
        <div class="title">{{item.titleShort}}</div>
      </div>
      <div class="sectionBox main"
           :class="{active:isActive}">
        <div class="copyBox"
             :class="side">
          <div class="title titleFront">{{item.title}}</div>
          <div class="description">{{item.description}}</div>
          <a class="button"
            v-if="item.buttonAction || item.resourceVideoId || item.resourceDocumentId">
            {{displayBtnTxt}}&nbsp;>
          </a>
        </div>
      </div>
      <div class="sectionBox right"
           :class="{active:isActiveRight}">
        <div class="title">{{item.titleShort}}</div>
      </div>
      <div class="sectionImage"
           v-if="item.asset"
           :class="{active:isActive,videoPreview:isVideo}"
           :style="this.bkgImg"></div>
      <div class="sectionVideo"
           v-if="item.resourceVideo"
           :class="{active:isActive}">
        <transition name="fade">
          <div class="videoWrapper"
               v-if="side==='front'">
            <iframe :src="getVideo()"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen></iframe>
          </div>
        </transition>
      </div>
      <div class="sectionAdmin"
           v-if="isAdmin && side==='front'"
           :class="{[side]: true, active:isActive}">
        <font-awesome-icon :icon="['fas', 'pencil']"
                           @click.stop="showCarouselAdmin" />
      </div>
    </div>
  </div>
</template>

<script>
import Helper from '../../mixins/helperMethods.vue';

export default {
  name: 'TileSide',
  mixins: [Helper],
  props: [
    'item',
    'side',
    'isActive',
    'isActiveLeft',
    'isActiveRight',
    'isClicked',
    'isVideo',
    'leftAnimation',
    'rightAnimation',
  ],
  data() {
    return {
      isHovering: false,
      bkgImg: '',
    };
  },
  watch: {
    item() {
      this.getImage();
    },
  },
  computed: {
    displayBtnTxt() {
      return this.$mq === 'sm' ? this.item.buttonTextShort : this.item.buttonText;
    },
    isAdmin() {
      return this.$store.getters['userStore/isAdmin'];
    },
  },
  methods: {
    clickedAction() {
      this.$emit('selectedTile', this.side);
    },
    async getImage() {
      if (!this.item || !this.item.asset) {
        return;
      }
      const asset = await this.$store.dispatch('assetStore/cachedAsset', this.item.assetId);
      this.bkgImg = `background-image: url(${asset.img})`;
    },
    getVideo() {
      return `https://player.vimeo.com/video/${this.item.resourceVideo.videoId}?background=1&autoplay=1&loop=1&byline=0&title=0`;
    },
    showCarouselAdmin() {
      this.$emit('showCarouselAdmin');
    },
    tileOver() {
      this.isHovering = true;
    },
    tileOut() {
      this.isHovering = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tileSide{
    position:relative;
    align-items:center;
    cursor:pointer;
    .containerTile{
      position:relative;
      width:100%;
      height:200px;
      background:$LincolnGray;
      vertical-align:middle;
      transition:height 0.150s ease-in-out;
      z-index:10;
      .sectionBox{
        opacity:0;
        position:absolute;
        top:0;
        left:0;
        width:50px;
        height:inherit;
        background-color:$LincolnGrayDark;
        color:white;
        text-align:center;
        z-index:12;
        display:flex;
        align-items:center;
        overflow:hidden;
        .title{
          width:180px;
          transition:top 0.150s ease-in-out;
        }
        &.left{
          justify-content: center;
          padding: 0 10px;
          z-index: 999;

          .title{
            text-transform:uppercase;
            transform:rotate(270deg);
            font-size: 0.875rem;
          }
        }
        &.main{
          top:-15px;
          left:60%;
          width:40%;
          min-height:200px;
          height:100%;
          max-height:300%;
          padding:15px;
          text-align:center;
          z-index:13;
          .copyBox{
            width:100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title{
              text-transform:uppercase;
              font-weight:bold;
              text-align:left;
              font-size:0.875rem;
              @include respond(md){
                font-size:1rem;
              }
            }
          }
          .titleFront{
            width: 90%;
          }
          .description{
            padding:15px 0;
            margin-bottom: 10px;
            text-align:left;
            font-size: 0.875rem;
            @include respond(md)
            {
              font-size: 1rem;
            }
          }
          a{
            padding:10px;
            background:$LincolnGrayLight;
            color:$LincolnGrayDark;
            font-size: 0.625rem;
            text-transform:uppercase;
            font-weight:bold;
            text-decoration:none;
            border-top:4px solid $LincolnGrayLight;
            border-bottom:4px solid $LincolnOrange;
            border-left:4px solid $LincolnGrayLight;
            border-right:4px solid $LincolnGrayLight;
            transition:all 0.300s ease-in-out;

            @include respond(md) {
              font-size: 0.875rem;
            }
          }
          &.active{
            animation-name:sectionBoxAnimation;
            animation-duration:1.0s;
            animation-timing-function:ease-out;
            animation-fill-mode:forwards;
            animation-direction:reverse;
          }
        }
        &.right{
          left:calc(100% - 50px);
          justify-content: center;
          padding: 0 10px;

          .title{
            text-transform:uppercase;
            transform:rotate(90deg);
            font-size: 0.875rem;
          }
        }
        &.active{
          animation-name:sectionBoxAnimation;
          animation-duration:1.0s;
          animation-timing-function:ease-out;
          animation-fill-mode:forwards;
        }
      }
      .sectionImage {
        position:absolute;
        top:0;
        left:0;
        width:60%;
        height:inherit;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
        z-index: 12;
        &.active{
          animation-name:sectionImageLeftAnimation;
          animation-duration:1.0s;
          animation-timing-function:ease-out;
          animation-fill-mode:forwards;
        }
      }
      .sectionVideo{
        position:relative;
        width:100%;
        height:100%;
        overflow:hidden;
        opacity:0;
        z-index:12;
        .videoWrapper{
          position:absolute;
          top:50%;
          left:0;
          width:200%;
          height:200%;
          background-size:cover;
          background-repeat:no-repeat;
          background-position:center;
          z-index:11;
          iframe{
            position:absolute;
            top:50%;
            left:50%;
            width:60%;
            height:100%;
            min-width:400px;
            min-height:269px;
            transform:translate(-100%, -100%);
          }
        }
        &.active{
          animation-name:sectionBoxAnimation;
          animation-duration:1.0s;
          animation-timing-function:ease-out;
          animation-fill-mode:forwards;
        }
      }
      .sectionAdmin{
        position:absolute;
        top:10px;
        left:10px;
        width:50px;
        height:50px;
        background:RGBA(0,0,0,0.5);
        border:2px solid #000;
        border-radius:100%;
        opacity:0;
        z-index:999;
        transition:all 0.350s ease-in-out;
        animation-name:sectionAdminAnimation;
        animation-duration:0.350s;
        animation-timing-function:ease-in-out;
        animation-fill-mode:forwards;
        svg{
          color:#FFF;
          font-size:26px;
          transform:translate(10px, 10px);
          &:hover{
            color:$LincolnOrange;
          }
        }
        &.active{
          animation-name:sectionAdminAnimation;
          animation-duration:0.350s;
          animation-timing-function:ease-in-out;
          animation-fill-mode:forwards;
          animation-direction:reverse;
        }
      }
    }
    &.left{
      .sectionBox{
        &.left{
          opacity:1;
        }
      }
      .sectionImage {
        left:50px;
        width:calc(100% - 50px);
      }
    }
    &.front{
      .containerTile{
        height:264px;
        .sectionBox{
          &.main{
            opacity:1;
          }
        }
      }
    }
    &.front.hover{
      .containerTile{
        .sectionBox.main{
          a{
            background:#FFF;
            color:$LincolnGray;
            border:4px solid $LincolnOrange;
          }
        }
      }
    }
    &.right{
      .sectionBox{
        &.right{
          opacity:1;
        }
      }
      .sectionImage {
        width:calc(100% - 50px);
      }
    }
    &.left.active,&.right.active,&.leftAnimation,&.rightAnimation{
      .containerTile{
        animation-name:containerAnimation;
        animation-duration:1.0s;
        animation-timing-function:ease-out;
        animation-fill-mode:forwards;
      }
    }
    &.leftAnimation,&.rightAnimation{
      .containerTile{
        animation-direction:reverse;
        .sectionBox.main{
          animation-direction:normal;
        }
      }
    }
    &.leftAnimation{
      .sectionBox.main{
        animation-direction:normal;
      }
      .sectionBox.right {
        animation-name:sectionBoxAnimation;
        animation-duration:1.0s;
        animation-timing-function:ease-out;
        animation-fill-mode:forwards;
        animation-direction:reverse;
      }
      .sectionImage.active{
        width:calc(100% - 50px);
        animation-name:sectionImageLeftReverseAnimation;
        animation-duration:1.0s;
        animation-timing-function:ease-out;
        animation-fill-mode:forwards;
      }
    }
    &.rightAnimation{
      .sectionBox.left{
        animation-name:sectionBoxAnimation;
        animation-duration:1.0s;
        animation-timing-function:ease-out;
        animation-fill-mode:forwards;
        animation-direction:reverse;
      }
      .sectionImage.active{
        width:calc(100% - 50px);
        animation-name:sectionImageRightAnimation;
        animation-duration:1.0s;
        animation-timing-function:ease-out;
        animation-fill-mode:forwards;
      }
    }
    &.front{
      .sectionVideo{
        opacity:1;
      }
    }
  }
  @keyframes containerAnimation {
    0%{
      height:200px;
    }
    25% {
      height:200px;
    }
    75% {
      height: 264px;
    }
    100%{
      height:264px;
    }
  }
  @keyframes sectionBoxAnimation {
    0% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    100% {
      opacity:0;
    }
  }
  @keyframes sectionImageLeftAnimation {
    0% {
      left:50px;
      width:calc(100% - 50px);
    }
    25%{
      left:0;
      width:100%;
    }
    75%{
      left:0;
      width:100%;
    }
    100% {
      left:0;
      width:60%;
    }
  }
  @keyframes sectionImageLeftReverseAnimation {
    0% {
      left:0;
      width:60%;
    }
    25%{
      left:0;
      width:100%;
    }
    75%{
      left:0;
      width:100%;
    }
    100% {
      left:0;
      width:calc(100% - 50px);
    }
  }
  @keyframes sectionImageRightAnimation {
    0% {
      left:0;
      width:calc(100% - 50px);
    }
    25%{
      left:0;
      width:100%;
    }
    75%{
      left:0;
      width:100%;
    }
    100% {
      left:50px;
      width:calc(100% - 50px);
    }
  }
  @keyframes sectionAdminAnimation {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  .fade-enter-active,.fade-leave-active {
    transition:opacity .5s;
  }
  .fade-enter,.fade-leave-to {
    opacity:0;
  }
</style>
