var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carouselAdmin" },
    [
      _c("h2", [_vm._v("New and Noteworthy Carousel - Admin")]),
      _c("ImageCropper", {
        staticClass: "imagePreviewer",
        attrs: { imageData: _vm.item },
        on: { updatedImage: _vm.updatedImage },
      }),
      _c(
        "div",
        { staticClass: "relatedContent" },
        [
          _c("h2", [_vm._v("Related Content")]),
          _c("p", [
            _vm._v(
              "Please choose the related article or resource this tile relates to."
            ),
          ]),
          _c("BrandStories", {
            staticClass: "brandStoryContainer",
            model: {
              value: _vm.item.brandStoryId,
              callback: function ($$v) {
                _vm.$set(_vm.item, "brandStoryId", $$v)
              },
              expression: "item.brandStoryId",
            },
          }),
          _c("ResourceDocuments", {
            staticClass: "resourceDocumentContainer",
            model: {
              value: _vm.item.resourceDocumentId,
              callback: function ($$v) {
                _vm.$set(_vm.item, "resourceDocumentId", $$v)
              },
              expression: "item.resourceDocumentId",
            },
          }),
          _c("ResourceVideos", {
            staticClass: "resourceVideoContainer",
            model: {
              value: _vm.item.resourceVideoId,
              callback: function ($$v) {
                _vm.$set(_vm.item, "resourceVideoId", $$v)
              },
              expression: "item.resourceVideoId",
            },
          }),
          _c("WhpcStories", {
            staticClass: "whpcStoryContainer",
            model: {
              value: _vm.item.whpcStoryId,
              callback: function ($$v) {
                _vm.$set(_vm.item, "whpcStoryId", $$v)
              },
              expression: "item.whpcStoryId",
            },
          }),
        ],
        1
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit($event)
            },
          },
        },
        [
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Tile Title (Long)",
              name: "title",
              required: true,
              maxLength: 50,
            },
            model: {
              value: _vm.item.title,
              callback: function ($$v) {
                _vm.$set(_vm.item, "title", $$v)
              },
              expression: "item.title",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Tile Title (Short)",
              name: "titleShort",
              required: true,
              maxLength: 24,
            },
            model: {
              value: _vm.item.titleShort,
              callback: function ($$v) {
                _vm.$set(_vm.item, "titleShort", $$v)
              },
              expression: "item.titleShort",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Tile Description",
              name: "description",
              required: true,
              maxLength: 140,
            },
            model: {
              value: _vm.item.description,
              callback: function ($$v) {
                _vm.$set(_vm.item, "description", $$v)
              },
              expression: "item.description",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Button Text (Long)",
              name: "buttonText",
              required: true,
              maxLength: 20,
              placeholder: "Read More",
            },
            model: {
              value: _vm.item.buttonText,
              callback: function ($$v) {
                _vm.$set(_vm.item, "buttonText", $$v)
              },
              expression: "item.buttonText",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Button Text (Short)",
              name: "buttonTextShort",
              required: true,
              maxLength: 10,
              placeholder: "More",
            },
            model: {
              value: _vm.item.buttonTextShort,
              callback: function ($$v) {
                _vm.$set(_vm.item, "buttonTextShort", $$v)
              },
              expression: "item.buttonTextShort",
            },
          }),
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Button URL",
              name: "buttonUrl",
              required: !this.btnUrlDisabled,
              disabled: this.btnUrlDisabled,
              placeholder: "https://effortlessguide.dealerconnection.com/",
            },
            model: {
              value: _vm.item.buttonUrl,
              callback: function ($$v) {
                _vm.$set(_vm.item, "buttonUrl", $$v)
              },
              expression: "item.buttonUrl",
            },
          }),
          _c("SelectElem", {
            attrs: {
              label: "Button Action",
              name: "buttonAction",
              id: "buttonActionSelect",
              required: !this.btnUrlDisabled,
              disabled: this.btnUrlDisabled,
              selectOptions: _vm.btnActionOptions,
            },
            model: {
              value: _vm.item.buttonAction,
              callback: function ($$v) {
                _vm.$set(_vm.item, "buttonAction", $$v)
              },
              expression: "item.buttonAction",
            },
          }),
          _c("button", { attrs: { type: "submit" } }, [_vm._v("Save")]),
          _c(
            "button",
            {
              staticClass: "cancel",
              attrs: { type: "button" },
              on: { click: _vm.closeModal },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }