var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("WHPC Story")]),
      _c("SelectElem", {
        attrs: {
          name: "whpcStory",
          id: "whpcStorySelect",
          selectOptions: _vm.whpcStories,
        },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.whpcStoryIdValue,
          callback: function ($$v) {
            _vm.whpcStoryIdValue = $$v
          },
          expression: "whpcStoryIdValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }