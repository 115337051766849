<template>
  <div @mouseleave="leaveBtn" class="dropDownBtnWrapper" :class="showClass">
    <div @mouseover="overBtn" class="dropDownBtn" :class="showClass">
      <div class="title" :class="showClass">
        <div class="txt">{{name}}</div>
      </div>
    </div>
    <div class="dropDownElementContainer" :class="showClass">
      <DropDownBtnElement
        v-for="(business) in this.dropDownItems"
        :key="'businessArea' + business.id"
        :id="business.id"
        :name="business.name"
        @selectedItem="selectedItem"
      ></DropDownBtnElement>
    </div>
  </div>
</template>

<script>
import DropDownBtnElement from './DropDownBtnElement.vue';

export default {
  name: 'DropDownBtn',
  props: ['id', 'name', 'dropDownItems'],
  components: {
    DropDownBtnElement,
  },
  data() {
    return {
      showClass: '',
    };
  },
  methods: {
    overBtn() {
      this.showClass = 'open';
    },
    leaveBtn() {
      this.showClass = '';
    },
    selectedItem(area) {
      this.$store.dispatch('tenetStore/setCurrentTenet', {
        id: this.id,
        name: this.name,
      });
      this.$store.dispatch('businessAreaStore/setCurrentArea', area);
      this.showClass = '';

      this.$analytics.trackEventBtnPageWithMetaInfo('FilterBrandStories',
        { tenetId: this.id, areaId: area.id });
    },
  },
  computed: {
    addShowClass() {
      return this.showClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropDownBtnWrapper {
  height: 3.5rem;

  &.open {
    height: auto;
  }
}

.dropDownBtn {
  background-color: $LincolnOrange;
  color: white;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  @include font-size(1rem);
  font-weight: $bold;
  white-space: nowrap;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1138px) {
    border-right: 1px solid white;
  }

  &:hover {
    cursor: pointer;
  }

  &.open {
    @media (min-width: 1138px) {
      color: black;
      background-color: $LincolnGrayLighter;
      font-weight: $bold;
    }
  }

  .title {
    .txt {
      display: inline-block;

      &::after {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        background: $LincolnOrange;
      }
    }
  }
}
.dropDownElementContainer {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out;

  &.open {
    transform: scaleY(1);
    transition: all 0.3s ease-in-out;
    color: white;
  }
}
.dropDownElementContainer > * {
  flex: 1 100%;
}
</style>
