var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("Brand Story")]),
      _c("SelectElem", {
        attrs: {
          name: "brandStory",
          id: "brandStorySelect",
          selectOptions: _vm.brandStories,
        },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.brandStoryIdValue,
          callback: function ($$v) {
            _vm.brandStoryIdValue = $$v
          },
          expression: "brandStoryIdValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }