var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropDownBtnWrapper",
      class: _vm.showClass,
      on: { mouseleave: _vm.leaveBtn },
    },
    [
      _c(
        "div",
        {
          staticClass: "dropDownBtn",
          class: _vm.showClass,
          on: { mouseover: _vm.overBtn },
        },
        [
          _c("div", { staticClass: "title", class: _vm.showClass }, [
            _c("div", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.name))]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "dropDownElementContainer", class: _vm.showClass },
        _vm._l(this.dropDownItems, function (business) {
          return _c("DropDownBtnElement", {
            key: "businessArea" + business.id,
            attrs: { id: business.id, name: business.name },
            on: { selectedItem: _vm.selectedItem },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }